
import { message } from 'ant-design-vue'
import { saveAs } from 'file-saver'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import useAnalysis from '@/components/analysis/composable/analysis'
import useBase from '@/components/analysis/composable/base'
import useJob from '@/components/analysis/composable/job'
import { inputNodeMapper, outputNodeMapper } from '@/components/analysis/composable/whatif'
import { AnalysisTask, getInputOuputNodes } from '@/components/analysis/libs/common'
import { objectId } from '@/libs/utils'
import { exportJobTaskStochastic } from '@/services/api/jobTask'
import { JobType } from '@/types/database/job'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL',
  ON_TOGGLE_SHOW: 'toggleVisible',
  REFRESH: 'refresh'
}

export const useStochasticSetup = (): any => {
  const isStochasticSetup = ref(false)
  const toggleStochasticSetup = () => {
    isStochasticSetup.value = !isStochasticSetup.value
  }
  return {
    isStochasticSetup,
    toggleStochasticSetup
  }
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const replicationSize: Ref<number> = ref(1)
    const sampleSize: Ref<number> = ref(1000)
    const status: Ref<string> = ref('')

    const ok = () => {
      emit(EVENTS.OK)
      emit(EVENTS.ON_TOGGLE_SHOW)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
      emit(EVENTS.ON_TOGGLE_SHOW)
    }

    const {
      networks,
      loadWorkspace,
      loadNetworks
      // workspace
    } = useBase(props.workspaceId)

    const { currentUser, nodeSelections, nodeSelectionMap } = useAnalysis(
      props.workspaceId,
      JobType.MONTECARLO,
      networks
    )

    const currentUserId = computed(() => currentUser.value?.id)

    const {
      currentJob,
      tasks,
      loadJob,
      loadTasks,
      persistJob,
      persistTasks,
      executeTask,
      removeTask
    } = useJob(
      currentUser,
      props.workspaceId,
      JobType.MONTECARLO,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      () => {
        message.success('Changes successfully applied')
      }
    )

    onMounted(() => {
      loadWorkspace()
      loadNetworks()
      updateConfig()
    })

    const setupMasterTask = () => {
      const network = networks.value[0]
      const config = {
        monteCarloConfig: {
          surveyId: props.surveyId,
          userId: currentUserId.value,
          replicationSize: replicationSize.value,
          sampleSize: sampleSize.value
        }
      }
      const _task: AnalysisTask = Object.assign(
        {
          id: objectId(),
          name: network.name,
          networkId: network.id,
          isPersisted: false,
          status: 'MASTER',
          config
        },
        getInputOuputNodes(network, nodeSelections.value)
      )
      tasks.value = [_task]
    }

    watch(networks, async () => {
      updateStatus()
    })

    watch(
      () => props.isVisible,
      () => {
        updateStatus()
      }
    )

    const init = async () => {
      if (tasks.value.length) {
        const masterTasks = tasks.value.filter((t: AnalysisTask) => t.status === 'MASTER')
        if (masterTasks.length && masterTasks[0].id) {
          removeTask(masterTasks[0].id, 0)
        }
      }

      setupMasterTask()
      await persistJob()
      await persistTasks()
    }

    const refresh = async () => {
      emit(EVENTS.REFRESH, false, tasks.value)
    }

    const refreshRaw = async () => {
      emit(EVENTS.REFRESH, true)
    }

    const getMasterTask = () => {
      const masterTasks = tasks.value.filter((t: AnalysisTask) => t.status === 'MASTER')
      if (masterTasks.length && masterTasks[0].id) {
        return masterTasks[0]
      }
    }

    const execute = async () => {
      const masterTask = getMasterTask()
      if (masterTask) {
        await executeTask(masterTask)
        status.value = 'Running...'
        updateTaskCount()
      }
    }

    const updateTaskCount = async () => {
      await loadJob()
      const { taskCount, taskCompletedCount } = currentJob.value || {}
      if (!isNaN(taskCompletedCount) && !isNaN(taskCount)) {
        status.value = taskCompletedCount + '/' + taskCount
      }
    }

    const updateConfig = async () => {
      const masterTask = getMasterTask()
      if (masterTask) {
        replicationSize.value = masterTask.config?.monteCarloConfig?.replicationSize || 1
        sampleSize.value = masterTask.config?.monteCarloConfig?.sampleSize || 1000
      }
    }

    const updateStatus = async () => {
      await updateTaskCount()
      await loadTasks()
      updateConfig()
    }

    const exportResults = async () => {
      const masterTask = getMasterTask()
      if (masterTask) {
        exportJobTaskStochastic(masterTask.id).then((res: any) => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          saveAs(blob, 'stochastic.zip')
        })
      }
    }

    return {
      refreshRaw,
      exportResults,
      updateStatus,
      init,
      refresh,
      execute,
      status,
      ok,
      cancel,
      replicationSize,
      sampleSize
    }
  }
})
