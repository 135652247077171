import { message } from 'ant-design-vue'
import { Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import { getStochasticRaw } from '@/services/api/stochastics'

import { AnalysisTask } from '../analysis/libs/common'

export default function useStochasticResults(workspaceId: string, surveyId: string): any {
  const stochasticResults: Ref<string> = ref('')
  const stochasticResultMap: Ref<Record<string, number[]>> = ref({})

  const onResultsGetRawSuccess = (data: string) => {
    stochasticResults.value = data
    const map: Record<string, number[]> = {}
    const fmap: Record<number, string> = {}
    data.split('\n').forEach((row: string, index: number) => {
      if (!index) {
        row.split(',').forEach((field: string, index: number) => {
          const ftrim = field.trim()
          map[ftrim] = []
          fmap[index] = ftrim
        })
      } else {
        row.split(',').forEach((field: string, index: number) => {
          const v = parseFloat(field)
          if (!isNaN(v)) {
            map[fmap[index]].push(v * 100)
          }
        })
      }
    })
    stochasticResultMap.value = map
    message.success('Stochatic results loaded')
  }

  const processResultsFromTasks = (tasks_: AnalysisTask[]) => {
    const map: Record<string, number[]> = {}
    tasks_.forEach((task: AnalysisTask) => {
      const stats = task.result?.monteCarloResults?.stats
      if (!stats) {
        return
      }
      stats.forEach((obj: any) => {
        Object.keys(obj).forEach((key: string) => {
          if (!map[key]) {
            map[key] = []
          }
          const value = obj[key]?.mean || 0
          map[key].push(value * 100)
        })
      })
    })
    stochasticResultMap.value = map
    message.success('Stochatic results processed')
  }

  const { runAsync: runGetRawResults } = useRequest(getStochasticRaw, {
    manual: true,
    onSuccess: onResultsGetRawSuccess
  })

  const loadStochasticResults = async (raw: boolean, tasks_: AnalysisTask[]) => {
    if (raw) {
      await runGetRawResults(workspaceId, surveyId)
    } else {
      processResultsFromTasks(tasks_)
    }
  }

  return {
    loadStochasticResults,
    stochasticResultMap
  }
}
