import { render } from "./SurveyStochasticsView.vue?vue&type=template&id=1677172a"
import script from "./SurveyStochasticsView.vue?vue&type=script&lang=ts"
export * from "./SurveyStochasticsView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1677172a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1677172a', script)) {
    api.reload('1677172a', script)
  }
  
  module.hot.accept("./SurveyStochasticsView.vue?vue&type=template&id=1677172a", () => {
    api.rerender('1677172a', render)
  })

}

script.__file = "src/views/SurveyStochasticsView.vue"

export default script