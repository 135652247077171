
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import SurveyStochastics from '@/components/survey/SurveyStochastics.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { isInvalidRouterParamId, normalizeRouterParamId } from '@/libs/utils'
import { useStore } from '@/store'
import { AllocationActionEnum } from '@/store/enums/actions/allocation'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { QuestionnaireActionEnum } from '@/store/enums/actions/questionnaire'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyStatusActionEnum } from '@/store/enums/actions/surveyStatus'
import { SurveySummaryActionEnum } from '@/store/enums/actions/surveySummary'
import { UserActionEnum } from '@/store/enums/actions/user'
import { QuestionnaireStateEnum } from '@/store/enums/states/questionnaire'
import { vuexActions } from '@/store/util'
// import { Allocation } from '@/types'

const { NETWORK, ALLOCATION, QUESTIONNAIRE, SURVEY, SURVEY_STATUS, SURVEY_SUMMARY } = ModuleNames

const guard: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  let { surveyId, workspaceId } = to.params
  let errorMessage
  if (isInvalidRouterParamId(surveyId)) {
    errorMessage = MESSAGE.UNEXPECTED_SURVEY_ID({ surveyId })
  }
  if (isInvalidRouterParamId(workspaceId)) {
    errorMessage = MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId)
  }
  if (errorMessage) {
    message.error({
      content: errorMessage,
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  }
  workspaceId = workspaceId as string
  surveyId = surveyId as string
  const store = useStore()
  await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
  await store.dispatch(vuexActions(SURVEY, SurveyActionEnum.GET_SURVEY), surveyId)
  await store.dispatch(vuexActions(SURVEY_SUMMARY, SurveySummaryActionEnum.GET_SURVEY_SUMMARIES), {
    surveyId
  })
  await store.dispatch(vuexActions(ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS), workspaceId)
  await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
    getAll: true
  })
  // const allocations = (
  //   await store.dispatch(vuexActions(ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS), workspaceId)
  // ).content
  // const userIds = allocations.map(({ userId }: Pick<Allocation, 'userId'>) => userId)
  // await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
  //   params: { id: userIds },
  //   getAll: true
  // })
  await store.dispatch(vuexActions(QUESTIONNAIRE, QuestionnaireActionEnum.GET_SURVEY_CPTS), {
    surveyId,
    getAll: true
  })
  const surveyCPTs = store.state[QUESTIONNAIRE][QuestionnaireStateEnum.SURVEY_CPTS]?.content

  if (surveyCPTs.length > 0) {
    next()
  } else {
    message.error({
      content: MESSAGE.CPT_NOT_SAVED,
      duration: 3,
      onClose: () => window.close()
    })
    next(false)
  }
  await store.dispatch(vuexActions(SURVEY_STATUS, SurveyStatusActionEnum.GET_SURVEY_STATUSES), {
    surveyId,
    getAll: true
  })
  await store.dispatch(vuexActions(QUESTIONNAIRE, QuestionnaireActionEnum.GET_SURVEY_RESPONSES), {
    surveyId
  })
}

export default defineComponent({
  components: {
    SurveyStochastics
  },
  beforeRouteEnter: guard as NavigationGuard,
  beforeRouteUpdate: guard as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const surveyId = computed(() => normalizeRouterParamId(route.params.surveyId))
    const workspaceId = computed(() => normalizeRouterParamId(route.params.workspaceId))
    return {
      surveyId,
      workspaceId
    }
  }
})
