function isArray(x: any) {
  return Array.isArray(x) || (ArrayBuffer.isView(x) && !(x instanceof DataView))
}

export class Summary {
  _data: number[] | null
  _sorted: number[] | null
  _length: number | null

  _cache_sum: number | null
  _cache_mode: number | null
  _cache_mean: number | null
  _cache_quartiles: Record<any, number>
  _cache_variance: number | null
  _cache_sd: number | null
  _cache_max: number | null
  _cache_min: number | null

  constructor(data: number[], sorted: boolean) {
    if (!isArray(data)) {
      throw TypeError('data must be an array')
    }

    this._data = data
    this._sorted = sorted ? data : null
    this._length = data.length

    this._cache_sum = null
    this._cache_mode = null
    this._cache_mean = null
    this._cache_quartiles = {}
    this._cache_variance = null
    this._cache_sd = null
    this._cache_max = null
    this._cache_min = null
  }

  //
  // Not all values are in lazy calculated since that wouldn't do any good
  //
  data(): number[] | null {
    return this._data
  }

  sort(): number[] | null {
    if (this._sorted === null && this._data != null) {
      this._sorted = this._data.slice(0).sort(function (a, b) {
        return a - b
      })
    }

    return this._sorted
  }

  size(): number | null {
    return this._length
  }

  //
  // Always lazy calculated functions
  //
  sum(): number | null {
    if (this._cache_sum === null && this._data != null && this._length != null) {
      // Numerically stable sum
      // https://en.m.wikipedia.org/wiki/Pairwise_summation
      const partials: number[] = []
      for (let i = 0; i < this._length; i++) {
        partials.push(this._data[i])
        for (let j = i; j % 2 == 1; j = j >> 1) {
          const p = partials.pop()
          const q = partials.pop()
          if (p && q) {
            partials.push(p + q)
          } else {
            partials.push(0)
          }
        }
      }

      let total = 0.0
      for (let i = 0; i < partials.length; i++) {
        total += partials[i]
      }
      this._cache_sum = total
    }
    return this._cache_sum
  }

  mode(): number | null {
    if (this._cache_mode === null && this._length != null) {
      const data = this.sort()

      let modeValue = NaN
      let modeCount = 0
      let currValue = data?.[0]
      let currCount = 1

      // Count the amount of repeat and update mode variables
      for (let i = 1; i < this._length; i++) {
        if (data?.[i] === currValue) {
          currCount += 1
        } else {
          if (currCount >= modeCount) {
            modeCount = currCount
            modeValue = currValue || 0
          }

          currValue = data?.[i]
          currCount = 1
        }
      }

      // Check the last count
      if (currCount >= modeCount) {
        modeCount = currCount
        modeValue = currValue || 0
      }

      this._cache_mode = modeValue
    }

    return this._cache_mode
  }

  mean(): number | null {
    if (this._cache_mean === null && this._length != null) {
      // Numerically stable mean algorithm
      let mean = 0
      for (let i = 0; i < this._length; i++) {
        mean += ((this._data?.[i] || 0) - mean) / (i + 1)
      }
      this._cache_mean = mean
    }

    return this._cache_mean
  }

  quartile(prob: number): number {
    const size = this.size()
    if (!this._cache_quartiles[prob] && size != null) {
      const data = this.sort() || []
      const product = prob * size
      const ceil = Math.ceil(product)

      if (ceil === product) {
        if (ceil === 0) {
          this._cache_quartiles[prob] = data?.[0] || 0
        } else if (ceil === data?.length) {
          this._cache_quartiles[prob] = data[data.length - 1]
        } else {
          this._cache_quartiles[prob] = (data[ceil - 1] + data[ceil]) / 2
        }
      } else {
        this._cache_quartiles[prob] = data[ceil - 1]
      }
    }

    return this._cache_quartiles[prob]
  }

  median(): number | null {
    return this.quartile(0.5)
  }

  variance(): number | null {
    if (this._cache_variance === null && this._data != null && this._length != null) {
      // Numerically stable variance algorithm
      const mean = this.mean() || 0
      let biasedVariance = 0
      for (let i = 0; i < this._length; i++) {
        const diff = this._data[i] - mean
        biasedVariance += (diff * diff - biasedVariance) / (i + 1)
      }

      // Debias the variance
      const debiasTerm = this._length / (this._length - 1)
      this._cache_variance = biasedVariance * debiasTerm
    }

    return this._cache_variance
  }

  sd(): number | null {
    const variance_ = this.variance()
    if (this._cache_sd === null && variance_ != null) {
      this._cache_sd = Math.sqrt(variance_)
    }
    return this._cache_sd
  }

  max(): number | null {
    const data_ = this.sort()
    if (this._cache_max === null && data_ != null && this._length != null) {
      this._cache_max = data_[this._length - 1]
    }

    return this._cache_max
  }

  min(): number | null {
    const data_ = this.sort()
    if (this._cache_min === null && data_ != null) {
      this._cache_min = data_[0]
    }

    return this._cache_min
  }
}
