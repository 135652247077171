<template>
  <!-- <div class="sz-workspace-menu">
    <div class="sz-vert-item">Properties</div>
    <div class="sz-vert-item">Survey</div>
    <div class="sz-vert-item">Variables</div>
  </div> -->
  <StochasticSetup
    v-if="workspaceId && surveyId"
    :workspace-id="workspaceId"
    :survey-id="surveyId"
    :is-visible="isStochasticSetup"
    @toggleVisible="toggleStochasticSetup"
    @refresh="loadResults"
  />
  <SurveyDialog :is-visible="isShowEditModal" @handleSetIsVisible="handleShowEditModal" />
  <div class="sz-survey-stochastic sz-wrapper">
    <div class="sz-command-bar">
      <a-space align="center" :size="0">
        <a-button type="link" @click="handleShowEditModal">
          <template #icon><FormOutlined /></template>
          Edit Survey
        </a-button>
        <a-popconfirm ok-text="Delete and publish" cancel-text="Cancel" @confirm="persistCPT">
          <template #title
            >Publish survey will delete <strong>old CPTs and responses</strong>, please
            confirm</template
          >
          <a-button type="link">
            <template #icon><ExportOutlined /></template>
            Publish Survey
          </a-button>
        </a-popconfirm>
        <!-- <a-button type="link" @click="getLink">
          <template #icon><OrderedListOutlined /></template>
          Open Survey Questionnaire
        </a-button> -->
        <router-link :to="{ name: ROUTE_NAME.SURVEY_STATUSES, params: { surveyId, workspaceId } }">
          <a-button type="link">
            <template #icon><PieChartOutlined /></template>
            Survey Status
          </a-button>
        </router-link>
        <router-link :to="{ name: ROUTE_NAME.SURVEY_ANALYTICS, params: { surveyId, workspaceId } }">
          <a-button type="link">
            <template #icon><RadarChartOutlined /></template>
            Analytics
          </a-button>
        </router-link>
        <a-dropdown placement="bottomCenter" :trigger="['click']">
          <a-button type="link">
            <template #icon><CloudOutlined /></template>
            Export Survey
          </a-button>
          <template #overlay>
            <a-menu @click="({ key }) => exportCPTResponse(key)">
              <a-menu-item :key="SurveyExportFormatEnum.CSV"> CSV format </a-menu-item>
              <a-menu-item :key="SurveyExportFormatEnum.JSON"> JSON format </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-button type="link" @click="toggleStochasticSetup">
          <template #icon><RadarChartOutlined /></template>
          Monte Carlo Job
        </a-button>
      </a-space>
    </div>
    <div class="sz-config">
      <div v-if="currentSurvey" class="sz-survey-summary">
        <div>
          <span>Survey Name</span>
          <span>{{ currentSurvey.name }}</span>
        </div>
        <div>
          <span>Status</span>
          <span>{{ currentSurvey.state }}</span>
        </div>
        <div>
          <span>Collection</span>
          <span>{{ currentSurvey.collectionMethod }}</span>
        </div>
        <div>
          <span>Scale</span>
          <span>{{ currentSurvey.scale && currentSurvey.scale.method }}</span>
        </div>
      </div>
    </div>
    <splitpanes class="default-theme">
      <pane min-size="10" size="30">
        <div class="sz-variables-container sz-survey-variables" :style="{ zIndex: 900 }">
          <VariableTable
            v-if="network && network.variables"
            :is-stochastic="true"
            :selected-variable="selectedVariable"
            :network="network"
            :current-survey="currentSurvey"
            :config="{
              allocationVisible: false,
              dependencyVisible: false,
              indexVisible: true,
              statesVisible: false,
              collectionMethodVisible: true,
              variableNameVisible: true
            }"
            :show-aux="false"
            v-on="{
              [EMIT_EVENTS.VARIABLE.SELECT]: selectVariable,
              [EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_CAIN]: toggleCain,
              [EMIT_EVENTS.SURVEY.VARIABLE_TOGGLE_OUTPUT]: toggleOutput
            }"
          />
        </div>
      </pane>
      <pane>
        <div v-if="selectedVariable" class="sz-cpt-container" :style="{ zIndex: 1000 }">
          <div v-if="outputMap[selectedVariable.name]">
            <a-button @click="showChart">Show Chart</a-button>
          </div>
          <CainTable
            v-if="network && cpt && isCain"
            :cpt="cpt"
            :network="network"
            :current-survey="currentSurvey"
            :verbose-state="verboseState"
            :discrete="hasDiscreteScale"
            :selected-variable="selectedVariable"
            :responses="selectedVariableResponses"
            :allow-partial-save="true"
            :is-stochastics="true"
            :editable="true"
            @[TABLE_EVENTS.ON_RESPONSES_CHANGE]="onResponsesSave"
          />
          <AceTable
            v-if="network && cpt && isAceDst"
            :network="network"
            :current-survey="currentSurvey"
            :cpt="cpt"
            :selected-variable="selectedVariable"
            :responses="selectedVariableResponses"
            :editable="true"
            :is-full="isAceDstFull"
            :is-stochastics="true"
            :allow-partial-save="true"
            @[TABLE_EVENTS.ON_RESPONSES_CHANGE]="onResponsesSave"
          />
          <MarginalTable
            v-if="network && cpt && cpt.isMarginal"
            :network="network"
            :current-survey="currentSurvey"
            :cpt="cpt"
            :allow-partial-save="true"
            :responses="selectedVariableResponses"
            :selected-variable="selectedVariable"
            :editable="true"
            :no-save="true"
            :is-stochastics="true"
            @[TABLE_EVENTS.ON_RESPONSES_CHANGE]="onResponsesSave"
          />
        </div>
        <a-modal
          :title="title"
          width="900px"
          :visible="isChartVisible"
          @ok="isChartVisible = false"
          @cancel="isChartVisible = false"
          @close="isChartVisible = false"
        >
          <stochastic-apex-chart :data="histData" />
        </a-modal>
      </pane>
    </splitpanes>
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CloudOutlined,
  ExportOutlined,
  FormOutlined,
  PieChartOutlined,
  RadarChartOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isArray } from 'lodash'
import { isEmpty } from 'lodash-es'
import { clone } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import useMethods from '@/components/composables/methods'
import useStochasticResults from '@/components/composables/stochastics'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import VariableTable from '@/components/variable/VariableTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'
import { Variable } from '@/libs/bayes/Variable'
import { Dict } from '@/libs/common'
import { publishSurvey } from '@/services/composition/survey'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { ResponseSchema, SurveyExportFormatEnum, User } from '@/types'

import { AnalysisTask } from '../analysis/libs/common'
import StochasticApexChart from './StochasticApexChart.vue'
import StochasticSetup, { useStochasticSetup } from './StochasticSetup.vue'

export default defineComponent({
  components: {
    StochasticApexChart,
    Pane,
    Splitpanes,
    AceTable,
    CloudOutlined,
    PieChartOutlined,
    ExportOutlined,
    FormOutlined,
    MarginalTable,
    VariableTable,
    CainTable,
    RadarChartOutlined,
    StochasticSetup,
    SurveyDialog
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    const { workspaceId, surveyId } = routerParams
    const verboseState = ref(false)
    const panels = reactive({
      variables: true,
      survey: true,
      cpt: true
    })
    const outputMap: Dict = ref({})

    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)

    const { loadStochasticResults, stochasticResultMap } = useStochasticResults(
      props.workspaceId,
      props.surveyId
    )

    const {
      cpt,
      cptSet,
      persistedResponses,
      persistResponses,
      currentNetwork,
      currentSurvey,
      network,
      hasDiscreteScale,
      selectedVariable,
      selectVariable
    } = useSurvey(store, true)

    watch(currentSurvey, () => {
      updateOutputMap()
    })

    const updateOutputMap = () => {
      if (currentSurvey.value?.ext?.outputMap) {
        outputMap.value = clone(currentSurvey.value?.ext?.outputMap)
      }
    }
    updateOutputMap()

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const isShowEditModal = ref(false)

    /**
     * Export CPT response for current survey
     * @param fileType - output file type
     **/
    const exportCPTResponse = (fileType: SurveyExportFormatEnum) => {
      if (surveyId && typeof surveyId === 'string' && !isEmpty(network.value.variables)) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network.value.variables.map((each: Variable) => each.id)
        })
      }
    }

    /**
     * Handle display create user model
     **/
    const handleShowEditModal = () => {
      isShowEditModal.value = !isShowEditModal.value
    }

    // http://localhost:3000/#/workspaces/608798785a1e581fa9eace8c/surveys/6095ed100d4da47b36cdceda/questionnaire
    const getLink = () => {
      window.open(`/#/workspaces/${workspaceId}/surveys/${surveyId}/questionnaire`)
    }

    const persistCPT = async () => {
      try {
        if (currentNetwork.value && cptSet.value && !isArray(surveyId) && !isArray(workspaceId)) {
          await publishSurvey(currentNetwork.value, cptSet.value, surveyId, workspaceId)
          message.success(MESSAGE.PERSIST_CPT_SUCCESS)
        }
      } catch (err) {
        message.error(MESSAGE.PERSIST_CPT_FAIL)
        throw err
      }
    }

    const toggleCain = async ({ variable, method }: { variable: Variable; method: CPTMethod }) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.collectionMethodMap = surveyData.ext.collectionMethodMap || {}
      surveyData.ext.collectionMethodMap[variable.id] = method
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }

    const toggleOutput = async ({
      variable,
      isOutput
    }: {
      variable: Variable
      isOutput: boolean
    }) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.outputMap = surveyData.ext.outputMap || {}
      if (isOutput) {
        outputMap.value[variable.name] = true
        surveyData.ext.outputMap[variable.name] = ['0.0', '1.0']
      } else {
        outputMap.value[variable.name] = false
        delete surveyData.ext.outputMap[variable.name]
      }
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }

    const isOperating = ref(false)
    const onResponsesSave = async ({ responses }: { responses: Array<ResponseSchema> }) => {
      isOperating.value = true
      try {
        persistResponses(responses, true) // true for analytics/ stochastic
        message.success(MESSAGE.CPT_RESPONSES_SAVE_SUCCESS)
      } catch (err) {
        message.error(MESSAGE.CPT_RESPONSES_SAVE_FAIL)
        isOperating.value = false
        throw err
      }
      isOperating.value = false
    }

    const selectedVariableResponses = computed(() => {
      const variableId = selectedVariable.value?.id
      if (variableId) {
        return persistedResponses.value.filter(
          (response: ResponseSchema) => response.variableId === variableId
        )
      }
      return []
    })

    const loadResults = (raw: boolean, tasks_: AnalysisTask[]) => {
      loadStochasticResults(raw, tasks_)
    }

    const histData = computed(() => {
      if (stochasticResultMap.value && selectedVariable.value) {
        return stochasticResultMap.value[selectedVariable.value.name]
      }
      return null
    })

    const isChartVisible = ref(false)

    const showChart = () => {
      isChartVisible.value = true
    }

    return {
      histData,
      isChartVisible,
      showChart,
      stochasticResultMap,
      outputMap,
      loadResults,
      ...useStochasticSetup(),
      selectedVariableResponses,
      toggleOutput,
      TABLE_EVENTS,
      onResponsesSave,
      DB_ENUM_VALUES,
      EMIT_EVENTS,
      persistCPT,
      handleShowEditModal,
      isShowEditModal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isAceOriginal,
      isCain,
      isMarginal,
      cpt,
      cptSet,
      hasDiscreteScale,
      currentSurvey,
      selectedVariable,
      getLink,
      toggleCain,
      selectVariable,
      exportCPTResponse,
      verboseState,
      network,
      panels,
      SurveyExportFormatEnum,
      ROUTE_NAME
    }
  }
})
</script>

<style lang="stylus">
@import '../styles/Page.styl';

.sz-survey-summary
  display flex
  flex-direction row
  > div
    > span
      display inline-block
    > span:first-child
      text-align right
      padding-right 5px
    > span:nth-child(2)
      font-weight 700
      width: calc(100% - 100px)
      padding-right 15px

.sz-config
  overflow: auto
  display: flex
  flex-direction: row
  padding: 5px 0 6px 12px
  background-color: white

.sz-survey-page
  display: flex
  flex-direction: column

  .sz-survey-variables
    position absolute
    top 0
    right 0
    left 0
    bottom 0
  // .sz-survey-variables
  //   ::-webkit-scrollbar
  //     width 0
</style>
