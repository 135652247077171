import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'

export const getStochasticResults = async (workspaceId: string, surveyId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.STOCHASTIC_RESULTS({ workspaceId, surveyId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export const getStochasticRaw = async (workspaceId: string, surveyId: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.STOCHASTIC_RAW({ workspaceId, surveyId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}
