import { render } from "./StochasticSetup.vue?vue&type=template&id=e1879c62"
import script from "./StochasticSetup.vue?vue&type=script&lang=ts"
export * from "./StochasticSetup.vue?vue&type=script&lang=ts"

import "./StochasticSetup.vue?vue&type=style&index=0&id=e1879c62&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e1879c62"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e1879c62', script)) {
    api.reload('e1879c62', script)
  }
  
  module.hot.accept("./StochasticSetup.vue?vue&type=template&id=e1879c62", () => {
    api.rerender('e1879c62', render)
  })

}

script.__file = "src/components/survey/StochasticSetup.vue"

export default script