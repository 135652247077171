/* eslint-disable @typescript-eslint/no-empty-function */
<template>
  <div v-if="data?.length" class="sza-stochastic-apex-chart" :style="maxStyle">
    Bin number: <a-input-number v-model:value="numBin" :min="10" />
    <a-button type="primary" @click="redraw">Redraw</a-button>
    <apexchart
      ref="chartEl"
      type="bar"
      :width="'100%'"
      :height="500"
      :options="chartOptions"
      :series="series"
      :style="chartStyle"
    ></apexchart>
    <apexchart
      ref="chartEl2"
      type="boxPlot"
      :width="'100%'"
      :height="500"
      :options="chartOptions2"
      :series="series2"
      :style="chartStyle"
    ></apexchart>
    <ApexChartActions
      v-if="chartEl"
      :chart-el="chartEl"
      :is-maximize="isMaximize"
      @[CHART_EVENTS.ON_TOGGLE_MAXIMIZE]="toggle"
    />
  </div>
</template>

<script lang="ts">
import { range } from 'ramda'
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'

import ApexChartActions, {
  EVENTS as CHART_EVENTS
} from '@/components/analysis/common/chart-actions/ApexChartActions.vue'
import useMaximizer from '@/components/composables/maximizer-apex'
import { Dict } from '@/libs/common'
import { Summary } from '@/libs/summary'

const BIN_DEFAULT = 10

const binning = (data: number[], numBin = BIN_DEFAULT) => {
  if (data.length < 100) {
    numBin = 10
  }
  let min = Math.min(...data)
  let max = Math.max(...data)
  let w = (max - min) / numBin
  if (w == 0) {
    w = 1
    min = min - 0.5
    max = max + 0.5
  }
  const ticks = range(0, numBin).map((i) => min + w / 2 + i * w)
  const dataMap = range(0, numBin).reduce((acc: Dict, x) => {
    acc[x] = 0
    return acc
  }, {})
  data.forEach((x) => {
    let idx = Math.floor((x - min) / w)
    if (idx >= numBin) {
      idx = numBin - 1
    }
    dataMap[idx]++
  })
  return {
    series: [
      {
        name: 'histogram',
        type: 'bar',
        data: range(0, numBin).map((i) => ({ x: ticks[i], y: dataMap[i] }))
      }
    ],
    categories: ticks,
    min,
    max,
    maxY: Math.max(...Object.values(dataMap))
  }
}

export default defineComponent({
  name: 'StochasticApexChart',
  components: {
    ApexChartActions
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      default: 'histo-chart'
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 500
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    title: {
      default: undefined,
      type: String
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => undefined
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const chartEl: Ref<HTMLElement | null> = ref(null)
    const chartEl2: Ref<HTMLElement | null> = ref(null)
    const maxY = ref(10)
    const minX = ref(0)
    const maxX = ref(100)
    const minX2 = ref(0)
    const maxX2 = ref(100)
    const numBin = ref(BIN_DEFAULT)

    const series: Ref<any[]> = ref([])
    const categories: Ref<any[]> = ref([])
    const series2: Ref<any[]> = ref([])

    const updateData = (noSeries2 = false) => {
      if (!props.data || !props.data.length) {
        return
      }
      const result = binning(props.data as number[], numBin.value)
      series.value = result.series
      categories.value = result.categories
      maxY.value = result.maxY
      minX.value = result.min
      maxX.value = result.max

      if (!noSeries2) {
        const summary = new Summary(props.data as number[], false)
        minX2.value = result.min
        maxX2.value = result.max

        series2.value = [
          {
            data: [
              {
                x: 'b',
                y: [
                  summary.min(),
                  summary.quartile(0.25),
                  summary.mean(),
                  summary.quartile(0.75),
                  summary.max()
                ]
              }
            ]
          }
        ]
      }
    }

    watch(
      () => props.data,
      () => updateData()
    )

    onMounted(() => {
      updateData()
    })

    const chartOptions = computed(() => ({
      chart: {
        height: 450,
        width: '100%',
        zoom: {
          enabled: false
        }
      },
      yaxis: {
        min: 0,
        max: maxY.value,
        decimalsInFloat: 4,
        forceNiceScale: false
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 0
      },
      tooltip: {
        x: {
          formatter: (val: number) => val.toFixed(4)
        },
        y: {
          formatter: (val: number) => val.toFixed(0)
        }
      },
      xaxis: {
        type: 'numeric',
        decimalsInFloat: 4,
        categories: categories.value,
        min: minX.value,
        max: maxX.value
      },
      plotOptions: {
        bar: {
          columnWidth: '100%'
        }
      },
      legend: {
        position: 'bottom'
      },
      title: {
        text: props.title
      }
    }))

    const chartOptions2 = computed(() => ({
      chart: {
        type: 'boxPlot',
        height: 450,
        width: '100%',
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        type: 'numeric',
        decimalsInFloat: 4,
        min: minX2.value,
        max: maxX2.value
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      }
    }))

    const { isMaximize, toggle, chartStyle, maxStyle } = useMaximizer()

    const redraw = () => {
      updateData(true)
    }

    return {
      redraw,
      numBin,
      series2,
      chartEl,
      chartEl2,
      chartOptions2,
      CHART_EVENTS,
      chartStyle,
      maxStyle,
      series,
      isMaximize,
      toggle,
      chartOptions
    }
  }
})
</script>

<style lang="stylus">
.sza-whatif-apex-chart {
  background-color: white;
  .vue-apexcharts {
    min-height: 100% !important;
  }

}
</style>
